import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FloatmenuComponentModule } from './../floatmenu/floatmenu.component.module';
import { IonicModule } from '@ionic/angular';

import { PersonalePageRoutingModule } from './personale-routing.module';

import { PersonalePage } from './personale.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FloatmenuComponentModule,
    PersonalePageRoutingModule
  ],
  declarations: [PersonalePage]
})
export class PersonalePageModule {}
