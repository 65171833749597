import { Component,OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppModule } from '../app.module';


@Component({
  selector: 'app-aiuto',
  templateUrl: './aiuto.page.html',
  styleUrls: ['./aiuto.page.scss'],
})
export class AiutoPage implements OnInit {

  constructor(private MyApp : AppModule,
    private modalController: ModalController) { }

  ngOnInit() {
  }

  async closeModel() {    
    await this.modalController.dismiss();
  }

}
