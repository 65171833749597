import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule} from '../app.module';
import { Router } from '@angular/router';



@Component({
  selector: 'app-personale',
  templateUrl: './personale.page.html',
  styleUrls: ['./personale.page.scss'],
})
export class PersonalePage implements OnInit {
  public jSon:any;
  public sTitolo:string=this.MyApp.GetLSItem('nome_account');
  public showLoader:boolean;

  constructor(private http: HttpClient,                 
              private MyApp : AppModule,
              private router: Router,
              ) {}

  ngOnInit() {  
    this.showProgressBar();
  }

  showProgressBar() {
    this.showLoader = true;
  }

  hideProgressBar() {
    this.showLoader = false;
  }

  ionViewDidEnter() { 
    this.showProgressBar();   
    /*this.jSon = [];
    while (this.jSon[0]) {
      this.jSon.splice(0);
    }*/
    this.GetData();
  }

  GetDotazione(sIdx:string){
    localStorage.setItem('iIdxSchede',sIdx);
    localStorage.setItem('iItemCard','0');
    this.router.navigateByUrl('/tabs/articoli');
    this.MyApp.GetPackingList();
  }

  GetData(){
    var sFilter:string;
    
    sFilter = ' P.IDX>0 ';
    if (localStorage.getItem('findLetter')!='')       sFilter = sFilter + " AND UPPER(P.COGNOME) LIKE '"+localStorage.getItem('findLetter')+"%' ";
    if (localStorage.getItem('findCognome')!='')      sFilter = sFilter + " AND UPPER(P.COGNOME) LIKE '"+localStorage.getItem('findCognome')+"%' ";    
    if (localStorage.getItem('findBadge')!='')        sFilter = sFilter + " AND UPPER(P.BADGE) = '"+localStorage.getItem('findBadge')+"' ";    
    if (Number(localStorage.getItem('findIdxRep'))>0) sFilter = sFilter + ' AND P.IDXREPARTO = '+localStorage.getItem('findIdxRep');
    if (Number(localStorage.getItem('findIdxMan'))>0) sFilter = sFilter + ' AND P.IDXMANSIONE = '+localStorage.getItem('findIdxMan');
    
    if ((Number(localStorage.getItem('findFlgAtt')) == 1) && (Number(localStorage.getItem('findFlgDim')) == 1))
      sFilter = sFilter + ' AND P.IDXSTATO IN (1,3) '
    else {
      if (Number(localStorage.getItem('findFlgAtt')) == 1)  sFilter = sFilter + ' AND P.IDXSTATO=1 ';
      if (Number(localStorage.getItem('findFlgDim')) == 1)  sFilter = sFilter + ' AND P.IDXSTATO=3 ';
    }
    
    if (Number(localStorage.getItem('findFlgPaz')) == 1)  sFilter = sFilter + ' AND P.FLGPAZ=1 ';
    if (Number(localStorage.getItem('findFlgPaz')) == 0)  sFilter = sFilter + ' AND COALESCE(P.FLGPAZ,0)=0 ';

    console.log(btoa(sFilter));
    this.http.post(this.MyApp.Get_WS_PATH()+'PersonaleGet', 'CODINT='+this.MyApp.GetLSItem('sCodInt')+'&REPARTI=-1&FILTER='+btoa(sFilter), this.MyApp.WS_HEADER).subscribe
    ( data => {
      this.jSon = data;
      var errorCode = this.jSon.Status.errorCode;     
      if(errorCode == 0){  
        this.hideProgressBar();    
      }
      else {
        this.hideProgressBar();
        if (errorCode == this.MyApp.ERROR_ZEROREC) {
          this.MyApp.presentAlert('Attenzione!','Nessun dato trovato.');          
          this.router.navigateByUrl('/tabs/cerca');
        }
      }

    });
  }

}
