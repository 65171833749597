import { NgModule } from '@angular/core';
import { FloatmenuComponent } from './floatmenu.component';
import { CommonModule } from '@angular/common';  

@NgModule({
    imports: [CommonModule],
    exports: [FloatmenuComponent],
    declarations: [FloatmenuComponent],
    providers: [],
})
export class FloatmenuComponentModule {
}