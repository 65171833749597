import { Component, OnInit  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule} from '../app.module';
import {Location} from '@angular/common';

@Component({
  selector: 'app-articoli',
  templateUrl: 'articoli.page.html',
  styleUrls: ['articoli.page.scss']
})
export class articoliPage implements OnInit {
  iItemCard:string='0';  
  

  constructor(private http: HttpClient,
    private _location: Location,
    private MyApp : AppModule) {}

  doRefresh(event) {  
    setTimeout(() => {
      this.MyApp.GetPackingList();
      event.target.complete();
    }, 1000); 
  }  

  ngOnInit() {
    
  }

  ionViewDidEnter() {  
    this.iItemCard=localStorage.getItem('iItemCard');
    localStorage.setItem('sReparto',atob(this.MyApp.jsonScheda.Personale[this.iItemCard].DESREP));
    localStorage.setItem('sMansione',atob(this.MyApp.jsonScheda.Personale[this.iItemCard].MANSIONE));
    localStorage.setItem('sConsegna',atob(this.MyApp.jsonScheda.Personale[this.iItemCard].CONSEGNA));
    localStorage.setItem('sCliente',atob(this.MyApp.jsonScheda.Personale[this.iItemCard].CLIENTE));
  }

  GoBack(){
    this._location.back();
  }

}
