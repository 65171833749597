import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AccountPage } from '../account/account.page';


@Component({
  selector: 'app-floatmenu',
  templateUrl: './floatmenu.component.html',
  styleUrls: ['./floatmenu.component.scss'],
})
export class FloatmenuComponent implements OnInit {

  constructor(public modalController: ModalController,
              private router: Router) {}

  ngOnInit() {}


  disconnect(){
    this.router.navigateByUrl('/login?d=1'); 
  }

  async GoToAccount() {
    const modal = await this.modalController.create({
      component: AccountPage,
      cssClass: 'my-custom-modal-css'
    });
    return await modal.present();
  }

}
