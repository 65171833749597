import { IonicModule } from '@ionic/angular';
import { FloatmenuComponentModule } from './../floatmenu/floatmenu.component.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { articoliPage } from './articoli.page';

import { articoliPageRoutingModule } from './articoli-routing.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FloatmenuComponentModule,
    articoliPageRoutingModule
  ],
  declarations: [articoliPage]
})
export class articoliPageModule {}
