import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModule} from '../app.module';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-cerca',
  templateUrl: './cerca.page.html',
  styleUrls: ['./cerca.page.scss'],
})
export class CercaPage implements OnInit {
  public alphabets:any=[];  
  public jRep:any;
  public inLettera:string='';
  public inCognome:string='';
  public inBadge:string='';
  public inReparto:number=-1;
  public inMansione:number=-1;
  public inChkAttivi:boolean=true;
  public inChkDimiss:boolean=false;
  public inChkPaz:boolean=false; 
  public sTitolo:string=this.MyApp.GetLSItem('struttura');

  constructor(private http: HttpClient,
              private router: Router,
              private MyApp : AppModule,) { 
                for(var i=65; i<=90; i++){
                  this.alphabets.push(String.fromCharCode(i));
                }               
              }

  ngOnInit() {   
    this.GetData();
  }

  ionViewWillEnter() { 
    if (this.MyApp.GetLSItem('bNoMenu')=='true') {
      this.MyApp.presentAlert('Attenzione!','Nessuna abilitazione');
    }    
    this.GetData();
  }

  GetData(){
    this.http.post(this.MyApp.Get_WS_PATH()+'PersonaleGet', 'CODINT='+this.MyApp.GetLSItem('sCodInt')+'&REPARTI=1', this.MyApp.WS_HEADER).subscribe
    ( data => {
      this.jRep = data;
      /*var errorCode = this.jSon.Status.errorCode;     
      if(errorCode == 0){  

      }
      else {
        if (errorCode == this.MyApp.ERROR_ZEROREC) {
          this.MyApp.presentAlert('Attenzione!','Nessun dato trovato.');
          return false;
        }
      }
        */
    });
  }

  onChangeReparti(){
    this.inMansione = -1;
  }

  Cerca(){
    var selRep:string='';
    if (this.inReparto!=-1) {
      selRep = this.jRep.Reparti[this.inReparto].IDXREPARTO;
    }

    if ((selRep=='') && (this.inLettera=='') && (this.inCognome=='') && (this.inBadge=='')) {
      this.MyApp.presentAlert('Attenzione!','Scegliere almeno un parametro di ricerca.');
      return false;
    }

    localStorage.setItem('findLetter',this.inLettera.toUpperCase());
    localStorage.setItem('findBadge',this.inBadge.toUpperCase());
    localStorage.setItem('findCognome','');
    if (this.inCognome.length>=3) localStorage.setItem('findCognome',this.inCognome.toUpperCase());
    localStorage.setItem('findIdxRep',selRep);
    localStorage.setItem('findIdxMan',this.inMansione.toString());
    localStorage.setItem('findFlgAtt','0');
    localStorage.setItem('findFlgDim','0');
    localStorage.setItem('findFlgPaz','0');

    if (this.inChkAttivi) localStorage.setItem('findFlgAtt','1'); 
    if (this.inChkDimiss) localStorage.setItem('findFlgDim','1'); 
    if (this.inChkPaz)    localStorage.setItem('findFlgPaz','1');

    this.router.navigateByUrl('/tabs/personale');
  }

  Azzera(){
    this.inLettera = '';
    this.inCognome = '';
    this.inBadge = '';
    this.inReparto = -1;
    this.inMansione = -1;
    this.inChkAttivi =true;
    this.inChkDimiss =false;
    this.inChkPaz =false; 
  }

}
